<template>
  <div flat>
    <v-data-table
      :headers="dessertHeaders"
      :items="searchParcel"
      show-expand
      item-key="_id"
      :single-expand="true"
      hide-default-footer
      @page-count="pageCount = $event"
      class="elevation-1"
      :loading="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card-actions v-if="item.comment.length > 0">
            <v-spacer></v-spacer>
            <div class="text-center display-1 ma-3">Ticket Issues</div>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-simple-table dark v-if="item.comment.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Message</th>
                  <th class="text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ite, index) in item.comment" :key="index">
                  <td>
                    {{ ite.fullName }}
                  </td>
                  <td>{{ ite.comment }}</td>
                  <td>{{ DisplayTimeFormat(ite.time) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="text-center display-1 ma-3">Sender Details</div>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-simple-table dark>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Sender Name</th>
                  <th class="text-left">Phone Number</th>
                  <th class="text-left">PickUp Address</th>
                </tr>
              </thead>
              <tbody v-if="item.senderDetails">
                <tr>
                  <td>
                    {{ item.senderDetails.firstName }}
                    {{ item.senderDetails.lastName }}
                  </td>
                  <td>{{ item.senderDetails.phoneNumber }}</td>
                  <td>{{ item.pickUpLocation.Street }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td>
                    {{ item.offlineSenderDetails.firstName }}
                    {{ item.offlineSenderDetails.lastName }}
                  </td>
                  <td>{{ item.offlineSenderDetails.phoneNumber }}</td>
                  <td>{{ item.pickUpLocation.Street }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center display-1 ma-3">Delivery Details</div>
          <v-simple-table dark>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Contact Person</th>
                  <th class="text-left">Delivery Address</th>
                  <th class="text-left">Phone Number</th>
                  <th class="text-left">Distance Km</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ item.deliveryDetails.name }}</td>
                  <td>{{ item.deliveryDetails.Street }}</td>
                  <td>{{ item.deliveryDetails.mobile }}</td>
                  <td>{{ item.deliveryDetails.distance }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="item.AddByStaff" class="text-center display-1 ma-3">
            Office Booking
          </div>
          <v-simple-table dark v-if="item.AddByStaff">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Staff Name</th>
                  <th class="text-left">Phone Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ item.AddByStaff.personalInfo.firstName }}
                    {{ item.AddByStaff.personalInfo.lastName }}
                  </td>
                  <td>
                    {{ item.AddByStaff.personalInfo.phoneNumber }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="item.riderDetails" class="text-center display-1 ma-3">
            Rider Details
          </div>
          <v-simple-table dark v-if="item.riderDetails">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Staff Name</th>
                  <th class="text-left">Phone Number</th>
                  <th class="text-left">Assign Time</th>
                  <th class="text-left">Current Location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ item.riderDetails.personalInfo.firstName }}
                    {{ item.riderDetails.personalInfo.lastName }}
                  </td>
                  <td>
                    {{ item.riderDetails.personalInfo.phoneNumber }}
                  </td>
                  <td>{{ DisplayTimeFormat(item.assignTime) }}</td>
                  <td v-if="item.riderDetails.currentLocation">
                    {{ item.riderDetails.currentLocation.Street }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center display-1 ma-3">Timeline</div>
          <v-simple-table dark>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Action</th>
                  <th class="text-left">Phone Number</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="item.assignTime">
                  <td>Assign Time</td>
                  <td>
                    {{ DisplayTimeFormat(item.assignTime) }}
                  </td>
                </tr>
                <tr v-if="item.riderPickupTime">
                  <td>Pickup Time</td>
                  <td>
                    {{ DisplayTimeFormat(item.riderPickupTime) }}
                  </td>
                </tr>
                <tr v-if="item.completedTime">
                  <td>Delivery Time</td>
                  <td>
                    {{ DisplayTimeFormat(item.completedTime) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center display-1 ma-3">Parcel Details</div>
          <v-simple-table
            v-if="item.parcelItems && item.parcelItems.length > 0"
            dark
            class="mt-5"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Name</th>
                  <th class="text-center">Price</th>
                  <th class="text-center">QTY</th>
                  <th class="text-center">Deliver-QTY</th>
                  <th class="text-center">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="parcel.name" v-for="parcel in item.parcelItems">
                  <td v-if="parcel.name" class="text-center">
                    {{ parcel.name }}
                  </td>
                  <td v-if="parcel.name" class="text-center">
                    {{ DisplayCurrency(parcel.price) }}
                  </td>
                  <td v-if="parcel.name" class="text-center">
                    {{ parcel.qty }}
                  </td>
                  <td v-if="parcel.name" class="text-center">
                    {{ parcel.qtySold }}
                  </td>
                  <td v-if="parcel.name" class="text-center">
                    {{ DisplayCurrency(parcel.price * parcel.qtySold) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table
            v-if="item.parcelReturn && item.completedTime"
            dark
            class="mt-5"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Rider Return Status</th>
                  <th class="text-center">Return With</th>
                  <th class="text-center">Confirmation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    {{ item.returnStatus }}
                  </td>
                  <td class="text-center">
                    {{ item.returnWith }}
                  </td>
                  <td class="text-center">
                    <v-btn
                      color="pink"
                      dark
                      @click="
                        ConfirmReturn(item);
                        dialog = !dialog;
                      "
                    >
                      Confirm Return
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Return Policy
        </v-card-title>

        <v-card-text> Confirm Rider Return {{ item.returnWith }} </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" @click="confirmParcel()">Proceed </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import currencyFormatter from "currency-formatter";

export default {
  props: ["keyword"],
  data: () => ({
    dialog: false,
    item: {},
    dessertHeaders: [
      {
        text: "Tracking No",
        align: "start",
        value: "trackingNumber",
      },
      { text: "Status", value: "Status" },
      { text: "Pickup Address", value: "pickUpLocation.Street" },
      { text: "Office", value: "FulfillmentCenter.locationName" },
      { text: "Request Date", value: "createdAt" },
    ],
  }),
  computed: {
    searchParcel() {
      return this.$store.getters.searchParcel;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    ConfirmReturn(payload) {
      this.item = payload;
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    DisTime(payload) {
      return moment(payload).calendar();
    },
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    confirmParcel() {
      let payload = {
        token: this.getToken,
        parcelId: this.item._id,
      };
      this.$store.dispatch("ConfirmParcelReturn", payload);
      this.dialog = false;
    },
  },
  watch: {
    keyword: function (val) {
      if (val.length > 5) {
        let payload = {
          keywords: val,
          token: this.getToken,
        };
        this.$store.dispatch("searchParcel", payload);
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <div flat>
    <v-data-table
      v-if="customerStatus == 'online'"
      :headers="dessertHeaders"
      :items="searchUsers"
      item-key="_id"
      show-expand
      :single-expand="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.balance="{ item }">
        <span>{{ DisplayCurrency(item.wallet[0].balance) }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card-actions>
            <v-spacer />
            <div>
              <UserWalletTransactions :userId="item._id" />
            </div>
            <div>
              <MessageSystem :online="true" :userId="item._id" />
            </div>
            <div>
              <UserPackages :userId="item._id" :tab="tab" />
            </div>
            <div>
              <FundOnlineWallet :user="item" />
            </div>
            <v-spacer />
          </v-card-actions>
          {{ item.null }}
        </td>
      </template>
    </v-data-table>
    <v-data-table
      v-if="customerStatus == 'offline'"
      :headers="dessertHeaders"
      :items="searchUsers"
      item-key="_id"
      show-expand
      :single-expand="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.balance="{ item }">
        <span>{{ DisplayCurrency(item.balance) }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card-actions>
            <v-spacer />
            <div>
              <UserWalletTransactions v-if="tab == 0" :userId="item._id" />
            </div>
            <div>
              <MessageSystem :online="false" :userId="item._id" />
            </div>
            <div>
              <UserPackages :userId="item._id" />
            </div>
            <v-spacer />
          </v-card-actions>
          {{ item.null }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import currencyFormatter from "currency-formatter";
import UserPackages from "./UserPackages.vue";
import UserWalletTransactions from "./UserWalletTransactions.vue";
import MessageSystem from "./MessageSystem.vue";
import FundOnlineWallet from "./FundOnlineWallet.vue";

export default {
  props: ["keyword"],
  components: {
    UserPackages,
    UserWalletTransactions,
    MessageSystem,
    FundOnlineWallet,
  },
  data: () => ({
    dessertHeaders: [
      {
        text: "First Name",
        align: "start",
        value: "firstName",
      },
      { text: "Last Name", value: "lastName" },
      { text: "Wallet Balance", value: "balance" },
      { text: "Phone Number", value: "phoneNumber" },
    ],
  }),
  computed: {
    customerStatus() {
      return this.$store.getters.customerStatus;
    },
    searchUsers() {
      return this.$store.getters.searchUsers;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    DisTime(payload) {
      return moment(payload).calendar();
    },
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
  },
  watch: {
    keyword: function (val) {
      if (val.length > 3) {
        let payload = {
          keywords: val,
          token: this.getToken,
        };
        if (this.customerStatus == "online") {
          this.$store.dispatch("searchOnlineCustomer", payload);
        }
        if (this.customerStatus == "offline") {
          this.$store.dispatch("searchOfflineCustomer", payload);
        }
      }
    },
  },
};
</script>

<style>
</style>
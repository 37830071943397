var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"flat":""}},[(_vm.customerStatus == 'online')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.dessertHeaders,"items":_vm.searchUsers,"item-key":"_id","show-expand":"","single-expand":true,"hide-default-footer":"","loading":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.DisplayCurrency(item.wallet[0].balance)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('UserWalletTransactions',{attrs:{"userId":item._id}})],1),_c('div',[_c('MessageSystem',{attrs:{"online":true,"userId":item._id}})],1),_c('div',[_c('UserPackages',{attrs:{"userId":item._id,"tab":_vm.tab}})],1),_c('div',[_c('FundOnlineWallet',{attrs:{"user":item}})],1),_c('v-spacer')],1),_vm._v(" "+_vm._s(item.null)+" ")],1)]}}],null,false,3297365057)}):_vm._e(),(_vm.customerStatus == 'offline')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.dessertHeaders,"items":_vm.searchUsers,"item-key":"_id","show-expand":"","single-expand":true,"hide-default-footer":"","loading":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.DisplayCurrency(item.balance)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card-actions',[_c('v-spacer'),_c('div',[(_vm.tab == 0)?_c('UserWalletTransactions',{attrs:{"userId":item._id}}):_vm._e()],1),_c('div',[_c('MessageSystem',{attrs:{"online":false,"userId":item._id}})],1),_c('div',[_c('UserPackages',{attrs:{"userId":item._id}})],1),_c('v-spacer')],1),_vm._v(" "+_vm._s(item.null)+" ")],1)]}}],null,false,806508290)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
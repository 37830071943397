<template>
  <v-card height="100%" tile flat>
    <v-navigation-drawer permanent>
      <v-list>
        <v-list-item :inactive="true">
          <v-list-item-avatar>
            <Avatar />
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item :inactive="true" @click="navigate('User Profile')">
          <v-list-item-content>
            <v-list-item-subtitle class="title"> Station </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ getUser.fulfillment }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>MGT Parcel</v-list-item-title>
            <v-badge
              v-if="ParcelCount > 0"
              class="mt-3"
              color="pink"
              :content="ParcelCount + StationParcelCount"
            />
          </template>
          <v-list-item-group
            v-model="selectedItem"
            v-for="(item, i) in parcel"
            :key="i"
            :inactive="true"
            :class="$route.name === item.navigate ? 'highlighted' : 'pointer'"
          >
            <v-list-item :inactive="true" @click="navigate(item.navigate)">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                  <v-badge
                    v-if="ParcelCount > 0 && item.text == 'New Parcel'"
                    class="ml-3"
                    color="pink"
                    :content="ParcelCount"
                  />
                  <v-badge
                    v-if="
                      StationParcelCount > 0 && item.text == 'Station Parcel'
                    "
                    class="ml-3"
                    color="pink"
                    :content="StationParcelCount"
                  />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-list-item-group>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>MGT Rider</v-list-item-title>
          </template>
          <v-list-item-group
            v-model="selectedItem"
            v-for="(item, i) in Rider"
            :key="i"
            :inactive="true"
            :class="$route.name === item.navigate ? 'highlighted' : 'pointer'"
          >
            <v-list-item :inactive="true" @click="navigate(item.navigate)">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-list-item-group>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>MGT Customer</v-list-item-title>
          </template>
          <v-list-item-group
            v-model="selectedItem"
            v-for="(item, i) in Customer"
            :key="i"
            :inactive="true"
            :class="$route.name === item.navigate ? 'highlighted' : 'pointer'"
          >
            <v-list-item :inactive="true" @click="navigate(item.navigate)">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-list-item-group>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>MGT Hub</v-list-item-title>
          </template>
          <v-list-item-group
            v-model="selectedItem"
            v-for="(item, i) in items"
            :key="i"
            :inactive="true"
            :class="$route.name === item.navigate ? 'highlighted' : 'pointer'"
          >
            <v-list-item :inactive="true" @click="navigate(item.navigate)">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                  <v-shouldSetBadge class="ml-3" color="pink" content="1" />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-list-item-group>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>

      <div class="text-center mt-10">
        <v-btn @click="logout" rounded color="primary" dark> Logout </v-btn>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import Avatar from "./UserAvatar.vue";
export default {
  components: {
    Avatar,
  },
  data: () => ({
    selectedItem: 0,
    parcel: [
      {
        text: "New Parcel",
        icon: "mdi-package-variant",
        navigate: "newParcel",
      },
      {
        text: "Station Parcel",
        icon: "mdi-package-variant-closed",
        navigate: "StationParcel",
      },
      {
        text: "All Parcel",
        icon: "mdi-package-variant-closed",
        navigate: "AllParcel",
      },
      {
        text: "Add Parcel",
        icon: "mdi-package-variant-closed",
        navigate: "AddParcel",
      },
    ],
    Rider: [
      {
        text: "Manage Riders",
        icon: "mdi-bike",
        navigate: "ManageRider",
      },
      {
        text: "Add Rider",
        icon: "mdi-bike",
        navigate: "AddRider",
      },
    ],
    Customer: [
      {
        text: "Customers",
        icon: "mdi-account-group",
        navigate: "Customers",
      },
      {
        text: "Messaging System",
        icon: "mdi-email-send",
        navigate: "MessagingSystem",
      },
    ],
    items: [
      {
        text: "Revenue",
        icon: "mdi-chart-arc",
        navigate: "Revenue",
      },
      {
        text: "Manage Staff",
        icon: "mdi-human-capacity-decrease",
        navigate: "ManageStaff",
      },
      {
        text: "Office Inventory",
        icon: "mdi-clipboard-list",
        navigate: "Inventory",
      },
      {
        text: "Manage Vehicle",
        icon: "mdi-application",
        navigate: "ManageAsset",
      },
      {
        text: "System History",
        icon: "mdi-clipboard-list",
        navigate: "SystemActivities",
      },
      {
        text: "Settings",
        icon: "mdi-cog-transfer-outline",
        navigate: "Settings",
      },
      {
        text: "Fulfillment Center",
        icon: "mdi-clipboard-list",
        navigate: "FulfillmentCenter",
      },
    ],
  }),
  created() {
    this.NewParcelCount();
    this.GetStationParcelCount();
    this.sockets.subscribe(`newPackage/${this.getUser.centerId}`, () => {
      this.NewParcelCount();
      this.GetStationParcelCount();
    });
  },
  methods: {
    GetNewPackages() {
      let payload = {
        page: 1,
        token: this.getToken,
      };
      this.$store.dispatch("GetNewPackages", payload);
    },
    navigate(payload) {
      this.$router.push({ name: payload });
    },
    GetStationParcelCount() {
      let payload = {
        token: this.getToken,
      };
      this.$store.dispatch("GetStationParcelCount", payload);
    },
    NewParcelCount() {
      let payload = {
        token: this.getToken,
      };
      this.$store.dispatch("NewParcelCount", payload);
    },
    logout() {
      let payload = {
        route: this.$router,
      };
      this.$store.commit("setSignOut", payload);
    },
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
    ParcelCount() {
      return this.$store.getters.ParcelCount;
    },
    StationParcelCount() {
      return this.$store.getters.StationParcelCount;
    },
  },
};
</script>

<style scoped>
.pointer :hover {
  cursor: pointer;
  background-color: #40abf7 !important;
  color: #fff;
}
.highlighted {
  background-color: #40abf7 !important;
  color: #fff;
}
</style>
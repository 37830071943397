<template>
  <div class="main">
    <div class="side">
      <SideBar />
    </div>
    <div class="dashboard">
      <v-toolbar flat>
        <v-toolbar-title>
          Dashboard/<span class="pageName">{{ page }}</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <div
          v-if="page != 'Customers'"
          @click="dialog = !dialog"
          class="search"
        >
          Search Parcel
        </div>
        <div
          v-if="page == 'Customers'"
          @click="dialog1 = !dialog1"
          class="search"
        >
          Search Customers
        </div>
      </v-toolbar>
      <v-divider></v-divider>
      <router-view></router-view>
    </div>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
      <v-card>
        <v-toolbar color="purple" dark flat>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            :focus="true"
            append-icon="mdi-microphone"
            class="mx-4"
            flat
            hide-details
            v-model="keyword"
            label="Search Parcel"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
          ></v-text-field>
          <v-progress-circular
            v-if="TableLoading"
            indeterminate
            color="white"
          ></v-progress-circular>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="text-h2 pa-12">
          <SearchParcel :keyword="keyword" />
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog1"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-toolbar color="purple" dark flat>
          <v-btn icon dark @click="dialog1 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            :focus="true"
            append-icon="mdi-microphone"
            class="mx-4"
            flat
            hide-details
            v-model="keyword"
            label="Search Customers"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
          ></v-text-field>
          <v-progress-circular
            v-if="TableLoading"
            indeterminate
            color="white"
          ></v-progress-circular>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog1 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="text-h2 pa-12">
          <SearchCustomer :keyword="keyword" />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SideBar from "../components/SideNav";
import SearchParcel from "../components/SearchParcel.vue";
import SearchCustomer from "../components/SearchCustomer.vue";

export default {
  data() {
    return {
      dialog: false,
      dialog1: false,
      keyword: "",
    };
  },
  components: {
    SideBar,
    SearchParcel,
    SearchCustomer,
  },
  computed: {
    page() {
      return this.$route.name;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
  },
  watch: {
    dialog: function (val) {
      if (val == false) {
        this.$store.commit("setSearch", []);
        this.keyword = "";
      }
    },
    dialog1: function (val) {
      if (val == false) {
        this.$store.commit("setSearchUsers", []);
        this.keyword = "";
      }
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
.main {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(100vh, auto);
  margin: 0 auto;
  padding: 0px;
  grid-template-areas: "side dashboard dashboard dashboard dashboard";
}
.side {
  grid-area: side;
}
.dashboard {
  grid-area: dashboard;
  height: 120vh;
  overflow: scroll;
}
.dashboard::-webkit-scrollbar {
  width: 0.4em;
}
.dashboard::-webkit-scrollbar-thumb {
  background-color: #193471;
  border-radius: 10px;
}
.pageName {
  text-transform: capitalize;
}
.search {
  height: 50px;
  width: 300px;
  background-color: #4287f5;
  border-radius: 35px;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}
</style>
<template>
  <v-container fluid>
    <v-row justify="center">
      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="brown" size="60">
              <span class="white--text text-h6">
                {{ getUser.personalInfo.firstName.charAt(0)
                }}{{ getUser.personalInfo.lastName.charAt(0) }}
              </span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="brown">
                <span class="white--text text-h5">
                  {{ getUser.personalInfo.firstName.charAt(0)
                  }}{{ getUser.personalInfo.lastName.charAt(0) }}
                </span>
              </v-avatar>
              <v-divider class="ma-2" />
              <h3>
                {{ getUser.personalInfo.firstName }}
                {{ getUser.personalInfo.lastName }}
              </h3>
              <p class="text-caption mt-1">
                {{ getUser.personalInfo.phoneNumber }}
                <br v-if="getUser.personalInfo.email" />

                {{ getUser.personalInfo.email }}
                <br />
              </p>

              <v-divider class="my-3"></v-divider>
              <v-btn @click="navigate('User Profile')" depressed rounded>
                Edit Account
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn @click="navigate('ChangePassword')" depressed rounded>
                Change Password
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({}),
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    navigate(payload) {
      this.$router.push({ name: payload });
    },
  },
};
</script>